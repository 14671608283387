import ReportFooter from "../ReportFooter/ReportFooter";
import Input from "../Input/Input";
import Label from "../Label/Label";
import Logo from "./../../assets/images/Logo.png";
import { ROOT_URI } from "../../constants/constants";
import DynamicTextArea from "../DynamicTextArea/DynamicTextArea";
import "./firstpage.css";
import { useState } from "react";

const FirstPage = ({
  onFindingsChange,
  report,
  handleChange,
  handleArrayChange,
  totalPages,
  userId,
  onReportDetailsChange,
}) => {
  const [findings, setFindings] = useState("");
  const [pacemaker, setPacemaker] = useState(false);
  const [formData, setFormData] = useState({
    name: report.name || "",
    patientid: report.device_id || "",
    Indication: report.indication || "",
    gender: report.gender || "",
    age: report.age || "",
    duration: report.duration || "",
    organization: (report.hospital || "")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
    referredBy: report.doctorName || "",
    from: report.from || "",
    to: report.to || "",
    beats: report.total_beatscount || "",
    mrd_no: report.ip_op || "",
    pacemaker: report.hyper_lipidemia,
  });
// console.log(report)
  console.log('report!!!!!',report.hyper_lipidemia)
  const handleFindingsChange = (newFindings) => {
    setFindings(newFindings);
    if (onFindingsChange) {
      onFindingsChange(newFindings); // Pass it to the parent (Report.jsx)
    }
    // console.log(findings)
  };
  // Handler for input changes
  const onInputChange = (e) => {
    const { name, value } = e.target;
    console.log("selected", e.target.value);

    const updatedData = {
      ...formData,
      [name]: value,
    };
    console.log(updatedData);

    setFormData(updatedData);
    // console.log('first page ',name,value)

    // Call the parent callback function with updated data
    if (onReportDetailsChange) {
      onReportDetailsChange(updatedData);
    }
  };
  console.log("formdata!!!!!!!!!!!!", formData);
  let pvc12 = report.pvc[12];

  // Check if pvc12 contains an "@" and is not null/undefined
  if (pvc12 && pvc12.includes("@")) {
    // Separate the value and date part at the "@"
    let [pvc12Value, pvc12Date] = pvc12.split("@");

    // Safely extract time (HH:mm:ss) and date (YYYY-MM-DD) if pvc12Date exists
    let formattedTime = pvc12Date
      ? pvc12Date.match(/(\d{2}:\d{2}:\d{2})/)?.[0]
      : "";
    let formattedDate = pvc12Date
      ? pvc12Date
          .match(/(\d{4}-\d{2}-\d{2})/)?.[0]
          .split("-")
          .slice(1)
          .reverse()
          .join("-")
      : "";

    // Combine the value with the formatted time and date
    pvc12 = `${pvc12Value.trim()} ${formattedTime} ${formattedDate}`;

    // console.log("pvc12:", pvc12); // Example: "3 beats(60 bpm) 03:20:12 10-02"
  } else {
    console.error("Invalid format for pvc12 or missing '@'.");
  }

  let pvc13 = report.pvc[13];

  // Check if pvc13 contains an "@" and is not null/undefined
  if (pvc13 && pvc13.includes("@")) {
    // Separate the value and date part at the "@"
    let [pvc13Value, pvc13Date] = pvc13.split("@");

    // Safely extract time (HH:mm:ss) and date (YYYY-MM-DD) if pvc13Date exists
    let formattedTimepvc13 = pvc13Date
      ? pvc13Date.match(/(\d{2}:\d{2}:\d{2})/)?.[0]
      : "";
    let formattedDatepvc13 = pvc13Date
      ? pvc13Date
          .match(/(\d{4}-\d{2}-\d{2})/)?.[0]
          .split("-")
          .slice(1)
          .reverse()
          .join("-")
      : "";

    // Combine the value with the formatted time and date
    pvc13 = `${pvc13Value.trim()} ${formattedTimepvc13} ${formattedDatepvc13}`;

    console.log("pvc13:", pvc13); // Example: "2 beats(70 bpm) 04:30:45 11-03"
  } else {
    console.error("Invalid format for pvc13 or missing '@'.");
  }

  // Output the afib value for debugging
  // console.log("report!!!!!!!!!!!!!!!", report.afib);
  function formatAfibValue(afibData) {
    if (!afibData) {
      return ""; // Handle case when afibData is undefined or null
    }

    // Ensure the string contains " On " to avoid split errors
    const [beatsPart, datePart] = afibData.includes(" On ")
      ? afibData.split(" On ")
      : [afibData, ""];

    // Split the date to get day and month
    const date = datePart
      ? datePart
          .split("-")
          .slice(2)
          .concat(datePart.split("-").slice(1, 2))
          .join("-")
      : ""; // Get dd-mm

    // Format the output
    return `${beatsPart.trim()} ${date}`; // Return "9 beats(61 bpm) @02:13:42 10-02"
  }

  function formatAfibFastestValue(afibData) {
    if (!afibData) {
      return ""; // Handle case when afibData is undefined or null
    }

    // Ensure the string contains " on " to avoid split errors
    const [beatsPart, datePart] = afibData.includes(" on ")
      ? afibData.split(" on ")
      : [afibData, ""];

    // Split the date to get day and month
    const date = datePart
      ? datePart
          .split("-")
          .slice(2)
          .concat(datePart.split("-").slice(1, 2))
          .join("-")
      : ""; // Get dd-mm

    // Format the output
    return `${beatsPart.trim()} ${date}`; // Return "63 bpm (3 beats) @02:13:39 10-02"
  }
  const capitalizeEachWord = (str) => {
    console.log(str);
    // return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  console.log("total count", report.total_beatscount);
  return (
    <div className="report__page">
      {/* <img src={Template} alt="" className="report__template" /> */}
      <div className="report__header">
        <img src={Logo} alt="Logo" className="report__header-logo" />

        <div className="report__title">
          <p>Extented Holter</p>
          <p>Study Report</p>
        </div>

        <p className="report__underline-header report__analysis-time">
          Analysis Time
        </p>
      </div>
      <div
        className="report__form-container"
        style={{ left: "20px", top: "67.34px" }}
      >
        {/* FIRST ROW */}
        <div className="report__form-row">
          <Input
            label="Patient Name"
            name="name"
            labelWidth={73}
            inputWidth={161}
            value={formData.name}
            onChange={onInputChange}
          />
          <Input
            label="Gender"
            labelWidth={41}
            inputWidth={62}
            value={formData.gender}
            onChange={onInputChange}
            name="gender"
          />
          <Input
            label="Age"
            labelWidth={14}
            inputWidth={45}
            value={formData.age}
            placeholder="Age"
            name="age"
            onChange={onInputChange}
          />

          {/* <Label label="Day" name="day" labelWidth={50} showSeparator={false} />  */}

          <Label label="Duration" variant="small" labelWidth={33} />
          <Input
            value={formData.duration}
            name="duration"
            inputWidth={120}
            onChange={onInputChange}
          />
        </div>

        {/* SECOND ROW */}
        <div className="report__form-row second-row">
          {report.ip_op && report.ip_op !== "NA" ? (
            <Input
              label="MRD No."
              labelWidth={69}
              inputWidth={56}
              value={formData.mrd_no}
              name="ip_op"
              onChange={onInputChange}
            />
          ) : (
            <Input
              label="Patient ID"
              labelWidth={69}
              inputWidth={56}
              value={formData.patientid}
              name="device_id"
              onChange={onInputChange}
            />
          )}

          <Input
            label="Indication"
            labelWidth={45}
            inputWidth={90}
            value={formData.Indication}
            name="Indication"
            onChange={onInputChange}
          />
          <Input
            label="Analysed Beats"
            labelWidth={80}
            inputWidth={56}
            value={formData.beats}
            name="beats"
            onChange={onInputChange}
          />

          <Input
            label="From"
            labelWidth={27}
            inputWidth={125}
            value={formData.from}
            placeholder="From"
            name="from"
            className="from-container"
            onChange={onInputChange}
          />
        </div>
        {/* THIRD ROW */}
        <div className="report__form-row">
          <Input
            label="Organization"
            labelWidth={73}
            inputWidth={123}
            value={formData.organization}
            name="Organization"
            onChange={onInputChange}
          />

          <Input
            label="Referred By"
            labelWidth={64}
            inputWidth={146}
            value={formData.referredBy}
            name="doctorName"
            onChange={onInputChange}
          />

          <Input
            label="To"
            labelWidth={31}
            inputWidth={110}
            value={formData.to}
            name="to"
            placeholder="To"
            onChange={onInputChange}
          />

          {/* <Input
            label="BMI"
            labelWidth={30}
            inputWidth={50}
            value={parseInt(report.weight/((report.height/100)*(report.height/100)))}
            name="bmi"
          /> */}

          {/* <Input
            label="CHA2DS2-VASc Score"
            labelWidth={120}
            inputWidth={100}
            value={report.CHA2Vasc_Score}
            name="CHA2Vasc"
            placeholder="0"
          /> */}
        </div>
        {/* FOURTH ROW */}
        <div className="report__form-row">
          <div
            style={{
              marginLeft: "200px",
              marginTop: "-10px",
              fontSize: "11.1px",
              fontFamily: "Times, serif",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <label
                style={{
                  fontSize: "11.1px",
                  fontFamily: "Times, serif",
                  color: "#000",
                }}
              >
                Pacemaker:
              </label>
              <select
                name="pacemaker"
                onChange={onInputChange}
                value={formData.pacemaker}
                style={{
                  border: "none",
                  outline: "none",
                  background: "white",
                  appearance: "none",
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                  fontSize: "11.1px", // Set font size
                  fontFamily: "Times, serif", // Set font family
                  padding: "5px 8px", // Adjust padding
                  borderRadius: "4px",
                  cursor: "pointer",
                  width: "80px", // Adjust width to prevent too much expansion
                  textAlign: "center",
                  color: "rgb(55, 52, 53)",
                }}
              >
                <option
                  value="true"
                  style={{ fontSize: "11.1px", fontFamily: "Times, serif" }}
                >
                  Yes
                </option>
                <option
                  value="false"
                  style={{ fontSize: "11.1px", fontFamily: "Times, serif" }}
                >
                  No
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="report__mid-form-container report__mid-form-container--1">
        <div className="report__mid-form-header">
          <p>ATRIAL ECTOPICS</p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Total"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={140}
            value={report.pac[0]}
            // onChange={onInputChange}
          />
          <Input
            label="Isolated"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={100}
            value={report.pac[4]}
            // onChange={onInputChange}
          />
          <Input
            label="Couplet"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={100}
            value={report.pac[5]}
            // onChange={onInputChange}
          />
          <Input
            label="Longest"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={178}
            value={`${report.pac[12]}${report.pac12Date}`}
            // onChange={onInputChange}
          />
          {/* <Input
            showSeparator={false}
            label=" "
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={135}
            value={report.pac12Date}
            name="pac12Date"
          /> */}
          <Input
            label="Fastest"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={178}
            value={`${report.pac[13]} ${report.pac13Date}`}
            // onChange={onInputChange}
          />
          {/* <Input
            showSeparator={false}
            label=" "
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={135}
            value={report.pac13Date}
            name="pac13Date"
          /> */}
          <Input
            label="Trigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={126}
            value={report.pac[10]}
            // onChange={onInputChange}
          />
          <Input
            label="Bigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={126}
            value={report.pac[11]}
            // onChange={onInputChange}
          />
          {/* <Input
            label="Afib / Flutter"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={126}
            value={report.afib[0]}
          /> */}
        </div>
      </div>

      <div className="report__mid-form-container report__mid-form-container--2">
        <div className="report__mid-form-header">
          <p>VENTRICULAR ECTOPICS</p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Total"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={126}
            value={report.pvc[0]}
            // onChange={onInputChange}
          />
          <Input
            label="Isolated"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={126}
            value={report.pvc[4]}
            // onChange={onInputChange}
          />
          <Input
            label="Couplet"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={126}
            value={report.pvc[5]}
            // onChange={onInputChange}
            //
          />
          <Input
            label="Longest"
            labelFontFamily="Bookman Old Style"
            labelWidth={78}
            inputWidth={181}
            value={pvc12}
            // onChange={onInputChange}
          />

          <Input
            label="Fastest"
            labelFontFamily="Bookman Old Style"
            labelWidth={78}
            inputWidth={181}
            value={pvc13}
            // onChange={onInputChange}
          />

          <Input
            label="Trigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={140}
            value={report.pvc[10]}
            // onChange={onInputChange}
          />

          <Input
            label="Bigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={140}
            value={report.pvc[11]}
            // onChange={onInputChange}
          />
          <Input
            label="Episodes"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={140}
            value={report.vt[5]}
            // onChange={onInputChange}
          />
        </div>
      </div>

      <div className="report__mid-form-container report__mid-form-container--3">
        <div className="report__mid-form-header">
          <p>PAUSES</p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Pauses in excess of 2 Sec"
            labelFontFamily="Bookman Old Style"
            labelWidth={170}
            inputWidth={40}
            value={report.pausesIntwosec}
            name="pausesInExcess"
            // onChange={onInputChange}
          />
          <Input
            label="Pauses in excess of 2.5 Sec"
            labelFontFamily="Bookman Old Style"
            labelWidth={170}
            inputWidth={40}
            value={report.pausesInExcess}
            name="pausesInExcess"
            // onChange={onInputChange}
          />
          <Input
            label="Longest RR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={`${report.maxPause} Sec`}
            name="maxPause"
            // onChange={onInputChange}
          />
        </div>
      </div>

      {/* <div className="report__mid-form-container report__mid-form-container--4">
        <div className="report__mid-form-header">
          <p>
            HEART RATE
            <span
              style={{
                fontSize: "9.5px",
                fontFamily: "Bookman Old Style",
                paddingLeft: "2px",
              }}
            >
              [4 RR Interval]
            </span>
          </p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Maximum HR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.max_hr}
            name="max_hr"
          />
          <Input
            label="Minimum HR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.min_hr}
            name="min_hr"
          />
          <Input
            label="Average HR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.avg_heartrate}
            name="avg_heartrate"
          />
        </div>
      </div> */}
      <div className="report__mid-form-container report__mid-form-container--5">
        <div className="report__mid-form-header">
          <p>Afib / Flutter</p>
        </div>
        <div className="report__mid-form">
          {report.afib[0] === "0|0%" ? (
            <p className="afib-container">No AFIB Noted</p>
          ) : (
            <Input
              label="Burden"
              labelFontFamily="Bookman Old Style"
              labelWidth={65}
              inputWidth={140}
              value={report.afib[0]}
              // onChange={onInputChange}
            />
          )}
          {report.afib[4] != 0 && (
            <Input
              label="Longest"
              labelFontFamily="Bookman Old Style"
              labelWidth={70}
              inputWidth={193}
              value={formatAfibValue(report.afib[6])}
              // onChange={onInputChange}
            />
          )}
          {report.afib[4] != 0 && (
            <Input
              label="Fastest"
              labelFontFamily="Bookman Old Style"
              labelWidth={70}
              inputWidth={193}
              value={formatAfibFastestValue(report.afib[7])}
              // onChange={onInputChange}
            />
          )}
        </div>
      </div>

      <div className="report__images">
        {report.images.map((image) => (
          <div className="report__imageContainer" key={image.key}>
            <input className="report__image-title" defaultValue={image.name} />
            <img
              className="report__image"
              src={`${ROOT_URI}/assets/dist/user_records/${userId}/${image.key}/cropped_image/max_${image.key}.png`}
              alt=""
            />
          </div>
        ))}
      </div>

      <img
        className="report__pie-chart"
        src={`${ROOT_URI}/assets/dist/user_records/${userId}/main_pie_chart.png`}
        alt="PIE CHART"
      />

      <div className="report__findingsninterpretation">
        <div className="report__findings">
          <p className="header">FINDINGS</p>
          <div style={{ flex: 1 }}>
            <DynamicTextArea
              value={report.findings}
              onChange={handleFindingsChange}
            />
          </div>
        </div>
        {/* <div className="report__interpretation"> */}
        {/* <p className="header">INTERPRETATION</p> */}
        {/* <textarea
            type="paragraph"
            defaultValue={report.Interpretation}
            name="findings"
            className="content"></textarea> */}
        {/* </div> */}
      </div>

      <ReportFooter page="1" totalPage={totalPages} />
    </div>
  );
};

export default FirstPage;
